*{
	transition: .3s all!important;
  position: relative;
  font-family: vazir;
  text-align: justify;
  box-sizing: border-box;
  color: #464953;
}
/*@font-face {
  font-family: 'Norican';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/Norican.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}*/
@font-face {
  font-family: vazir;
  src: url(../assets/vazir.ttf);
}

:root{
  --sky: #eef5fb;
}

/* ::-webkit-scrollbar {
	display: none;
} */

.line-2{line-height: 2;}
.relative{ position: relative; }
.blocked{	display: block; }
.inline-blocked{ display: inline-block; }
.inlined{	display: inline; }.blocked{ display: block;}
.grid{display: grid;}
.flexed{ display: flex!important; }.fl-c{ justify-content: center; align-items: center; }
.row-reverse{flex-direction: row-reverse;}
.fdir-col{flex-direction: column;}
.align-end{ align-items: flex-end;}
.align-c{ align-items: center;}.justify-c{ justify-content: center;}
.justify-between{ justify-content: space-between;}.justify-end{ justify-content: flex-end;}
.z-1{ z-index: 1; }.z-2{ z-index: 2; }
.flex1{ flex: 1; }.flex2{	flex: 2; }.flex3{	flex: 3; }
.centered{ text-align: center; }
.pointer{	cursor: pointer; }
.circle{ border-radius: 50%; }
.radius-2{border-radius: 2px;}.radius-4{border-radius: 4px;}.radius-8{border-radius: 8px;}.radius-16{border-radius: 16px;}.radius-32{border-radius: 32px!important;}
.bold{ font-weight: bold; }
.width33{	width: 33.33%; }.width100{ width: 100%; }
.padding4{ padding: 4px; }.padding8{ padding: 8px; }
.font-8{ font-size: 8pt; }.font-10{ font-size: 10pt; }.font-16{ font-size: 16pt; }.font-20{ font-size: 20pt; }
.hidden{ display: none; }
.rtl{ direction: rtl;}.ltr{ direction: ltr;}
.left{ float: left; }.right{ float: right; }
.cleared{ clear: both; }
.minh-100vh{min-height: 100vh;}
.maxh-100vh{max-height: 100vh;}
.h-100vh{height: 100vh;}
.bg-darker{background-color: #242627;}.bg-silver{background-color: #777;}.bg-sky{background-color: var(--sky);}
.transparent{background: none;border: none;}
.overflow-hidden{overflow: hidden;}
.text-disabled{color: #888;}
.to-show, .to-show-f{display: none;}
hr{ height: 1px; }
.inset-shadow{box-shadow: inset 2px 2px 5px #232427, inset -5px -5px 10px #434343;}
.outset-shadow{box-shadow: inset 2px 2px 5px #434343, inset -5px -5px 10px #232427;}
.outset-shadow-lighter{box-shadow: inset 2px 2px 5px #716969, inset -5px -5px 10px #4f5052;}
.outset-shadow-light{box-shadow: inset -1px -1px 8px #e3e3e3, inset 5px 5px 6px #fff;}
.outset-shadow-light2{box-shadow: -2px -2px 8px #FFF, 2px 2px 8px #BABECC;}
.text-shadow{text-shadow: 1px 1px 0 #fff;}
.small, small {font-size: 64%!important;}
a:hover{text-decoration: none!important;}
.bg-none{background: none;}
.text-overflow-hidden{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
button{outline: none!important;}
.absolute{position: absolute;}
.p-8{padding: 8px;}.p-12{padding: 12px;}
.minw-128{min-width: 128px;}
.maxw-64{max-width: 64px;}.maxw-128{max-width: 128px;}
.maxw-768{
	max-width: 768px;
	width: 100%;
}
.maxw-420{
  max-width: 420px;
  width: 100%;
}
.bd-none{ border: none; }
.my-shadow{ box-shadow: 0 2px 4px #999; }
button:not(.force){cursor: pointer; min-width: 47px;justify-content: center;}
img:not(.force){max-height: 360px;}
img{
	object-fit: cover;
  max-width: 100%;
}
.sticky{
	position: sticky;
  top: 0;
  z-index: 1;
}
button.signout{
  position: absolute;
  bottom: 4px;
  left: calc(50% - 26px);
}

button:active, .button:active{transform: scale(.97);}

html, body, #app, .app-holder{
	height: 100%;
	max-height: 100%;
  text-align: justify;
  /* background-color: #f9f9f9!important; */
}
.thumb-sm{
	width: 50px;
  height: 50px;
}
.thumb{
	width: 64px;
  height: 64px;
}
.thumb-lg{
	width: 128px;
  height: 128px;
}
.Toastify__close-button{align-self: center!important; text-align: center;}
.Toastify__toast--warning{color: #464953;}

.modal-header .close {margin: -1rem auto -1rem -1rem!important;}

.hover-shadow:hover{box-shadow: 0 2px 4px #eaeaea;}
.text-shadow{filter: drop-shadow(2px 4px 6px #9999)}

.hero{
  background-image: url(./../images/swimming.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
}
.col-xs-6{width: 50%!important;}






.loading{
	min-height: 315px;
}
.loading.full{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: #1119;
}




.abadgaran{
  position: absolute;
  top: 10%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
  background: var(--sky);
  padding: 4%;
}







@media (max-width: 480px){
  .to-show{display: block;}
  .to-hide{display: none;}
  .to-show-f{display: flex;}
  .thumb-lg{width: 85px; height: 85px;}
}



.popIn{
	animation: popIn;
	animation-duration: .3s;
	animation-fill-mode: forwards;
}
.slideInTop, .emoji-picker-react{
	animation: slideInTop;
	animation-duration: .3s;
	animation-fill-mode: forwards;
}
.slideInBottom{
	animation: slideInBottom;
	animation-duration: .3s;
	animation-fill-mode: forwards;
}

@keyframes slideInTop{
	from{
		opacity: .2;
		transform: translateY(32px);
	}
}
@keyframes slideInBottom{
	from{
		opacity: .2;
		transform: translateY(-32px);
	}
}
@keyframes popIn{
	from{
		opacity: .2;
		transform: scale(.8);
	}
}
